<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">{{ name }} - iDirect Usage</h3>
		</div>
		<div class="card-body pt-0 d-flex flex-wrap justify-content-center widget-wrapper">
			<div class="col">
				<div class="green-head mb-2">
					<h4>{{ data.thistx }}B data <b>recieved</b> in the last 24 hours</h4>
				</div>
				<div class="row">
					<div class="col d-flex align-items-center stat-container">
						<div class="mx-1">
							<i :class="[data.txup ? 'flaticon2-arrow-up green' : 'flaticon2-arrow-down red']" />
						</div>
						<div>
							<h1 class="mb-0">{{ data.txchange }}%</h1>
						</div>
					</div>
					<div class="col d-flex flex-column justify-content-center">
						<div>
							<span class="bold">{{ data.txchangediff }}B</span> difference from previous 24 hours
						</div>
						<div>
							<span class="bold">{{ data.thistxavg }}bps</span> 24 hour average
						</div>
					</div>
					<div class="col d-flex flex-column justify-content-center">
						<div><span class="bold">Rx Busy Hour:</span></div>
						<div>{{ txstarttime }} - {{ txendtime }} UTC</div>
						<div>
							{{ data.busyhour.txvalkmg }}bps average
							<span v-b-tooltip.hover title="Average throughput during busy hour">
								(x&#x0304;<sub>bh</sub>)
							</span>
						</div>
						<div>{{ data.busyhour.txmaxkmg }}bps Max 5 minute average</div>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="blue-head mb-2">
					<h4>{{ data.thisrx }}B data <b>sent</b> in the last 24 hours</h4>
				</div>
				<div class="row">
					<div class="col d-flex align-items-center stat-container">
						<div class="mx-1">
							<i :class="[data.rxup ? 'flaticon2-arrow-up green' : 'flaticon2-arrow-down red']" />
						</div>
						<div>
							<h1 class="mb-0">{{ data.rxchange }}%</h1>
						</div>
					</div>
					<div class="col d-flex flex-column justify-content-center">
						<div>
							<span class="bold">{{ data.rxchangediff }}B</span> difference from previous 24 hours
						</div>
						<div>
							<span class="bold">{{ data.thisrxavg }}bps</span> 24 hour average
						</div>
					</div>
					<div class="col d-flex flex-column justify-content-center">
						<div><span class="bold">Tx Busy Hour:</span></div>
						<div>{{ rxstarttime }} - {{ rxendtime }} UTC</div>
						<div>
							{{ data.busyhour.rxvalkmg }}bps average
							<span v-b-tooltip.hover title="Average throughput during busy hour">
								(x&#x0304;<sub>bh</sub>)
							</span>
						</div>
						<div>{{ data.busyhour.rxmaxkmg }}bps Max 5 minute average</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Idirect24HourUsage',
	props: {
		name: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
	},
	computed: {
		txstarttime() {
			let d = new Date(this.data.busyhour.txstart * 1000).toISOString().split('T');
			return `${d[0]} ${d[1].substring(0, 5)}`;
		},
		txendtime() {
			let d = new Date((this.data.busyhour.txstart + 3600) * 1000);
			return `${d.getUTCHours()}:${d.getUTCMinutes()}`;
		},
		rxstarttime() {
			let d = new Date(this.data.busyhour.rxstart * 1000).toISOString().split('T');
			return `${d[0]} ${d[1].substring(0, 5)}`;
		},
		rxendtime() {
			let d = new Date((this.data.busyhour.rxstart + 3600) * 1000);
			return `${d.getUTCHours()}:${d.getUTCMinutes()}`;
		},
	},
};
</script>

<style scoped>
.widget-wrapper > .col {
	min-width: 500px;
}
.blue {
	color: #659be0;
}
.green {
	color: #37c583;
}
.red {
	color: #ed6b75;
}
.green-head {
	border-bottom: 3px solid #37c583;
}
.blue-head {
	border-bottom: 3px solid #659be0;
}
.stat-container i,
.stat-container h1 {
	font-size: 3em;
}
.bold {
	font-weight: 600;
}
</style>
